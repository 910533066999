//shades of blue
$palette-blue-100:    rgb(0, 85, 135);
$palette-blue-200:    rgb(0, 151, 169);



//shages of green
$palette-green-100:   rgb(134, 188, 37);

//shages of green
$palette-yellow-100:  rgb(198, 215, 12);


//shades of grey
$palette-grey-100:    rgb(66, 67, 68);
$palette-grey-101:    rgb(198, 198, 198);
$palette-grey-102:    rgb(99, 102, 106);
$palette-grey-103:    rgb(211,211,211);
$palette-grey-104:    rgb(97,97,97);
$palette-grey-105:    #555454;




//shades of white
$palette-white-100:   rgb(255, 255, 255);
$palette-white-101:   rgb(221, 219, 218);
$palette-white-200:   rgb(244,244,244);
$palette-white-201:   rgb(200,200,200);

//shades of black
$palette-black-100:   rgb(0,0,0);
$palette-black-102:   rgb(45,45,45);
$palette-black-primary: #141313;


//shades of red
$palette-red-100:     rgb(255,0,0);
$palette-red-101:     rgb(215, 12, 12);


//shades of orange
$palette-orange-100:   rgb(255,165,0);


