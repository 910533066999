.header {
  float: left;
  margin-top: $space-xs;
  font-size: $text-xs;
  width: 100%;
}

.mat-tab-group.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: transparent;
}

.shrinked {
  .feature {
    .tab-body {
      width: 100%;
    }
  }
}
.feature {
  .tab-body {
    // width: 82%;
    overflow-y: auto;
    // margin-top: 20px;
    margin-top: 0px;
    // padding-left: $text-base-size;
    max-height: 15rem;
    .file-btn {
      height: $text-xl !important;
    }
    .file-btn.disable {
      opacity: 0.4;
      cursor: not-allowed;
    }
    img {
      height: $text-md;
    }
    .add-cart-button {
      display: flex;
      width: 14%;
      button {
        border-radius: 5em / 5em;
        font-size: $text-xs;
      }
    }
    .document-button {
      width: 7%;
    }
    .tab-button {
      border: none;
      background-color: transparent;
    }
    .center {
      height: auto;
      margin: $space-xxxs auto;
      .header-1 {
        @extend .header;
        line-height: $text-md;
        font-weight: 700;
      }
      .header-2 {
        @extend .header;
        line-height: $text-md;
        font-weight: 600;
      }
      .last-level {
        padding-left: 1.5rem !important;
      }
      .last-level-l2 {
        padding-left: 3rem !important;
        color: #000000 !important;
        font-weight: 600 !important;
        font-family: "Open Sans" !important;
        font-style: normal !important;
        font-size: 0.8rem !important;
      }
      .other-last-level-l2 {
        padding-left: 1.5rem !important;
        color: #000000 !important;
        font-weight: 400 !important;
        font-family: "Open Sans" !important;
        font-style: normal !important;
        font-size: 14px !important;
      }
      .last-level-l3 {
        padding-left: 3.25rem !important;
        color: #000000 !important;
        font-weight: 600 !important;
        font-family: "Open Sans" !important;
        font-style: normal !important;
        font-size: 0.8rem !important;
      }
      .other-last-level-l3 {
        padding-left: 1.75rem !important;
        color: #000000 !important;
        font-weight: 400 !important;
        font-family: "Open Sans" !important;
        font-style: normal !important;
        font-size: 14px !important;
      }
    }
    .header-type {
      font-weight: 600;
      font-size: $text-xs;
      width: 26%;
      text-align: center;
      align-self: center;
    }
    .last-level-placeholder {
      margin-right: 2rem;
    }
    .header-description {
      font-weight: 500;
      font-size: $text-xs;
      padding-left: 3rem;
      width: 71%;
    }
    .heading-3 {
      @extend .header;
      display: flex;
      line-height: $text-md;
      align-items: center;
      margin-bottom: 1em;
      .title {
        font-weight: 600;
        font-size: $text-xs;
        width: 75%;
      }
      .title-type {
        font-weight: 600;
        line-height: $text-md;
        width: 26%;
        text-align: center;
      }
      .description {
        font-weight: 600;
        font-size: $text-xs;
        margin-left: 0.25em !important;
        width: 71%;
      }
      .width-40 {
        width: 40%;
      }
      div:first-child {
        padding-left: 3rem !important;
      }

      div:last-child {
        margin-left: auto;
        .file-btn {
          height: $text-xl;
        }
        .file-btn.disable {
          opacity: 0.4;
          cursor: not-allowed;
        }

        button {
          border-radius: 5em/5em;

          img {
            height: $text-md;
          }
        }
      }
    }
  }

  &.full-height {
    .tab-body {
      max-height: 24rem;
    }
  }
}

.feature-tabs {
  &.mat-tab-group {
    margin-bottom: 48px;
    // margin-top: 20px;
    margin-left: 3%;
    width: 80%;
  }
  &.insights {
    .mat-ink-bar {
      background-color: transparent !important;
    }

    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }

    .mat-tab-label-active,
    .mat-tab-group.mat-primary .mat-tab-label:focus,
    body .mat-tab-group.mat-primary .mat-tab-link:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }
  }
  &.deliver {
    .mat-ink-bar {
      background-color: transparent !important;
    }

    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }

    .mat-tab-label-active,
    .mat-tab-group.mat-primary .mat-tab-label:focus,
    body .mat-tab-group.mat-primary .mat-tab-link:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }
  }
  &.imagine {
    .mat-ink-bar {
      background-color: transparent !important;
    }

    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }

    .mat-tab-label-active,
    .mat-tab-group.mat-primary .mat-tab-label:focus,
    body .mat-tab-group.mat-primary .mat-tab-link:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }
  }
  &.run {
    .mat-ink-bar {
      background-color: transparent !important;
    }

    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-group.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-nav-bar.mat-primary
      .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }

    .mat-tab-label-active,
    .mat-tab-group.mat-primary .mat-tab-label:focus,
    body .mat-tab-group.mat-primary .mat-tab-link:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
    body .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
      font-weight: bold;
      text-shadow: 0 0 0.35px $palette-black-primary;
      background-color: lightgray;
    }
  }
  :host {
    &::ng-deep {
      .mat-ink-bar {
        background-color: $palette-yellow-100 !important;
      }
    }
  }
  .mat-tab-body-content {
    display: inline;
  }
}
.mat-expansion-panel {
  box-shadow: none !important;
  // border-top: 1px solid lightgrey;
  border-top: 1px solid #E1E1E1;
}
.mat-expansion-panel-body {
  // padding-left: 0px !important;
  // padding-right:0px !important;
  padding: 0px !important;
  .description {
    padding-left: 3rem !important;
  }
}
.mat-expansion-panel-header {
  padding-right: 0px !important;
  .mat-expansion-indicator {
    padding: 1rem !important;
  }
}
.justify-align {
  text-align: justify;
  &.project {
    padding-right: 16rem !important;
  }
  &.explore {
    padding-right: 6rem !important;
  }
}
.file-btn {
  height: $text-xl !important;
}
.file-btn.disable {
  opacity: 0.4;
  cursor: not-allowed;
}
.status-img {
  position: relative !important;
  //width: 15px !important;
  height: 15px !important;
  padding-left: 2px !important;
  padding-right: 7px !important;
}
.mat-expansion-panel-header {
  min-height: 3rem !important;
  height: auto !important;
}
