.btn-primary {
  padding: 5px;
  width: 141px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  border: none;
  color: #ffffff;
  background-color: #000000;
  float: right;
}

.phase-element-body {
  margin-top: 1em;
  margin-right: 1.5em;
  border: 1px solid #dcc8c0;
  background-color: #fffaf8;
  border-radius: 5px;
  // cursor: move;

  .phase-form-head {
    font-size: 16px;
    font-weight: 600;
    padding: 10px;

    .phase-form-title {
      display: inline;
    }

    .phase-form-actions {
      margin-left: auto;
      float: right;

      img {
        width: 25px;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 10px;

        &.img-control {
          display: none;
          width: auto;

          &.visible {
            display: inline;
          }
        }
      }

    }
  }

  .phase-field {
    display: inline-block;
    width: 28%;
    margin: 0 10px;

    .mat-form-field {
      display: block;
    }

  }
}

.required-asterisk {
  font-size: 12px;
}

.phase-option {
  margin: 0.5em;
}
