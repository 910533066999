.disableElementForExternalUser .mat-form-field-wrapper {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #F6F6F6;
  color: #a7a8aa;
}

.disableElementForExternalUser a,.badge,.selectedItems .disableElementForExternalUser ,
.sub-phase-btn.btn-spacing.disableElementForExternalUser,
.addSystem.disableElementForExternalUser,
.add-release.disableElementForExternalUser,
.add-assumption-parent.disableElementForExternalUser,
.button-position.disableElementForExternalUser,
.panel-footer .disableElementForExternalUser,
.collapse-panel-btn-edit .disableElementForExternalUser
{
  pointer-events: none;
  opacity: 0.7;
}

.disableElementForExternalUser mat-radio-button {
  pointer-events: none;
}
.disableElementForExternalUser {
    mat-radio-button.mat-radio-button.mat-accent .mat-radio-outer-circle {
    background-color: #F6F6F6 !important;
    cursor: not-allowed !important;
  }
}

.disableElementForExternalUser 
.mat-checkbox {
  cursor: default;
}
.disableElementForExternalUser .mat-checkbox-layout {
  pointer-events: none;
}
.disableElementForExternalUser .mat-checkbox-frame {
  background-color: #F6F6F6;
}

.disableElementForExternalUser.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.disableElementForExternalUser.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ccc !important;
}
.disableElementForExternalUser .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.disableElementForExternalUser .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ccc !important;
}

.disableElementForExternalUser {
  mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border: 5px solid #ccc !important;
}
}

.mat-menu-item.disableElementForExternalUser {
  pointer-events: none;
}


.disableElementForExternalUser .mat-form-field-wrapper {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #F6F6F6;
  color: #a7a8aa;
}

.external-user-toolTipText {
font-size: 11px;
/* text-align: center; */
padding: 0.3rem 0.5rem;
}

.mat-tooltip.external-user-toolTipText {
background-color: #2e3238;
color: #fff;
border-radius: 4px;
font-weight: 400;
font-size: 11px;
/* text-align: center; */
padding: 0.3rem 0.3rem 0.4rem 0.4rem;
margin: 0 0 1rem 28rem;
max-width: 25rem;
&::after {
  content: "";
  position: absolute;
  bottom: -10px;
  /* left: 35%; */
  border-width: 5px;
  border-style: solid;
  border-color: #2e3238 transparent transparent transparent;
}
}
