/*
    ---------------------------TYPOGRAPHY REFERNCES---------------------------


*/

$primary-font: "Open Sans";
$secondary-font:   "Montserrat";

/*  set  base values  */
$text-size:        16px;
$text-base-size:   1rem; //16px
$text-scale-ratio: 1.2rem; //19.2px

/*  size set  */
$text-xs:  0.8rem;  //12.8px
$text-sm:  0.9rem;  //14.4px
$text-md:   1.5rem; //24px
$text-lg:   2rem;   //32px
$text-xl:   2.5rem; //40px
$text-xxl:  3rem;   //48px
$text-xxxl: 3.5rem; //56px


 /* spacing values */
 $space-xxxs: 0.25rem; //4px
 $space-xxs:  0.375rem;//6px
 $space-xs:   0.5rem;  //8px
 $space-sm:   0.75rem; //12px
 $space-md:   1.25rem; //20px
 $space-lg:   2rem;    //32px
 $space-xl:   3.25rem; //52px
 $space-xxl:  5.25rem; //84px
 $space-xxxl: 8.5rem;  //136px