@use '@angular/material' as mat;

@import "bootstrap/dist/css/bootstrap.css";
@import "@progress/kendo-theme-material/scss/gantt/_index.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "./assets/styles/app.scss";
/* You can add global styles to this file, and also import other style files */
// @import url(http://fonts.googleapis.com/css?family=Open + Sans);


@include mat.core();
@include mat.legacy-core();
$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));
@include mat.all-component-themes($my-theme);
@include mat.all-legacy-component-themes($my-theme);


html,
body {
  overflow-x: hidden;
  height: 100%;
  background-color: whitesmoke;
  margin: 0;

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: rgb(255, 255, 255);
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgb(255, 255, 255);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(99, 102, 106, 0.2);
    border-radius: 3px;
  }
}

* {
  font-family: $primary-font, $secondary-font;
}

.required-asterisk {
  color: #da291c;
  font-size: 1.5em;
  vertical-align: middle;
}

.summaryPopupStyle .mat-dialog-container {
  padding: 0px;
  overflow-x: hidden;
  border-radius: 0px;
  overflow-y: hidden;
}

.infoPopupStyle .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
  background-color: #f4f4f4;
  background-image: url(./assets/ascend@3x.png);
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: 80% 95%;
  background-attachment: fixed;
  overflow-x: hidden;
  // border-bottom-left-radius: 35px;
  // border-bottom-right-radius: 35px;
}

.toolsbarPopupStyle .mat-dialog-container {
  padding: 0px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  background-color: #f4f4f4;
  background-image: url(./assets/ascend@3x.png);
  background-repeat: no-repeat;
  background-size: 350px;
  background-position: 87% 70%;
  background-attachment: fixed;
  overflow-x: hidden;
}

.videoPlayer .mat-dialog-container {
  padding: 0;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.73);
}
.project-confirm-dialog-wrapper {
  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden;
    border-radius: 0px;
  }
}

.infoPopupDefineRoadmapStyle {
  .mat-dialog-container {
    background-image: url(./assets/ascend@3x.png);
    background-repeat: no-repeat;
    background-size: 150px;
    background-position: 75% 65%;
    background-attachment: fixed;
    padding: 0 !important;
    overflow: hidden;
    border-radius: 0px;
  }
}

// Mat tooltip class for handling truncated text
.card-title-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}
//mat-tooltip to avoid overlapping of character count & project name in create project
.custom-tooltip {
  max-width: 140px !important;
}

.activity-status-menu {
  #cdk-overlay-0 {
    transform: unset !important;
  }
}

.mat-menu-class {
  .mat-menu-item {
    display: flex;
    align-items: center;
  }

  img {
    filter: invert(0.6);
    margin: 0 0.1rem 0.1rem 0.5rem;
  }
}

.ascend-white-button {
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #2e3238;
  font-weight: 700;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 13px;
  color: #2e3238;
  padding: 0 0.6rem;
  min-width: 150px;
  margin-right: 1.5rem;

  &:focus {
    box-shadow: none;
  }

  img {
    transform: rotate(180deg);
  }
}

.ascend-grey-button {
  height: 40px;
  background: #2E3238;
  border-radius: 4px;
  border: 1px solid #2E3238;
  font-weight: 700;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 13px;
  color: #FFFFFF;
  padding: 0 0.6rem;
  min-width: 150px;
  margin-right: 1.5rem;

  &:focus {
    box-shadow: none;
  }

  img {
    margin: 0 0.5rem 0 0;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.header-element-height {
  height: 2.5rem;
}
.report-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  padding: 0px 14px !important;
  line-height: 2rem !important;
}