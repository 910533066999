.banner-page {
    // display: flex;
    // flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 48px);

    .banner {
        background-color: #2E3238;
        padding: 1rem 4rem;

        .breadcrumb {
            .page-title {
                color: #FFFFFF;
            }
        }
    }

    .page-body {
        // overflow: auto;
        height: calc(100% - 136px);
        .toolbar {
            height: 60px;
            display: flex;
            align-items: flex-end;
        }
    }
}