/* You can add global styles to this file, and also import other style files */
@import './scss/variables.scss';

.mat-menu-panel {
    margin-top: 10px;
    width: 250px;
}


.phase_planning_mat-select-panel {
    margin-top: 35px;
    .mat-option-text {
        font-size: 13px;
        color: #59616c;
        font-weight: 600;
    }
    .mat-option.mat-selected:not(.mat-option-multiple) {
        background-color: #2e3238;
        .mat-option-text {
            color: #fff;
        }
    }
  }