/*
    ---------------------------MIXIN REFERNCES---------------------------

   
*/

/* Media queries */
$breakpoints: (  
    "desktop-width-1921":     1921px,
    "desktop-width-1601":     1601px,
    "desktop-width-1600":     1600px, 
    "desktop-width-1536":     1536px,    
    "desktop-width-1366":     1366px,
    "desktop-width-1281":     1281px,
    "desktop-width-1280":     1280px,
    "desktop-width-1098":     1098px,
    "desktop-width-855":      855px,    
    "desktop-width-854":      854px,   
);

@mixin media_query($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media screen and (#{$type}-width: $width) {
            @content;
        }
    }
}