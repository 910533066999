/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
/* montserrat-regular - latin */

  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
         url('/assets/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
          
  }

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('/assets/fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icon.woff2') format('woff2');
}

