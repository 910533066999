$color-primary-1: #002ea6;
$color-primary-2: #000000;
$train-width: 20%;
$train-width-right: 16.5%;
$page-body-width: 95%;
$additional-div-height: 30px;
$margin-primary: 0 3em;
$border-radius-primary: 15px;


.page-logo {
  font-weight: 600;
  font-size: 18px;
  line-height: 49px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0.5px 0 #ffffff;
  letter-spacing: 1px;
  padding: 3px;
  margin-top: 2em;
  margin-bottom: 1em;
  width: 94%;
}

.active-text {
  font-weight: 700;
  font-size: 14px;
}

.stepper-header {

  .circle {
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    float: left;
    color: white;
    font-size: 20px;
    margin-left: -2%;
    margin-top: -0.5%;
    background: #007680;
    border: 10px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    line-height: 1.2;
  }

  .circle-div {
    // width: 102.6%;
    height: 35px;
    color: white;
    line-height: 2;
    font-weight: 600;
    font-size: 16px;
    background-color: rgb(0, 118, 128, 1);
  }
}

.left-train {
  // width: $train-width;
  // float: left;
  // margin-top: 2.5%;
  display: flex;
  font-size: 14px;
  // width: 80%;
  margin: 0 auto;
  margin-top: 1.5em;
  margin-bottom: 1em;

  .icon-class {
    // display: flex;
    align-items: center;
    margin-bottom: 2em;
    height: 3.5em;
    width: 100%;

    .circle1-label {
      color: $color-primary-2;
      // width: 70%;
      // margin-right: 1em;
      text-align: center;
      cursor: pointer;
      font-size: 13px;
    }

    .circle1 {
      width: 28px;
      height: 28px;
      text-align: center;
      border-radius: 50%;
      background: #75787B;
      color: #ffffff;
      margin: 5px auto;
      cursor: pointer;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      // transition: all 0.5s ease-in-out;

      span {
        font-weight: 800;
        font-size: 14px;
        line-height: 28px;
      }
    }

    .left-step-line-div {
      float: left;
      width: 45%;
      margin-top: 9px;

      .step-line {
        border: solid;
      }
    }

    .right-step-line-div {
      float: right;
      width: 45%;
      margin-top: -29px;

      .step-line {
        border: solid;
      }
    }

  }

  .icon-class.active {
    .circle1 {
      background: #007680;
      // border: 2px solid #79bee6;
      // margin-bottom: 5px;
      box-shadow: 0px 4px 4px rgba(0, 118, 128, 0.05);
    }
  }
}

.right-stepper-action {
  padding-top: 2em;
  padding-bottom: 5em;

  button {
    padding: 5px;
    width: 132px;
    height: 44px;
    font-weight: bold;
    // border: 4px solid rgb(13, 131, 144, 1);
    border-radius: 0px;
    font-size: 12px;
    line-height: 16.34px;
    cursor: pointer;

    &.btn-next {
      background: #0D8390;
      color: white;
      float: right;
      box-sizing: border-box;
      border: 0;
      // border: 1px solid #C4C4C4;
    }

    &.btn-next:hover {
      box-shadow: 0 4px 12px 1px rgba(13, 131, 144, .2),
        0 3px 3px 0 rgba(13, 131, 144, .14),
        0 3px 5px 0 rgba(13, 131, 144, .12);
    }

    &.btn-prev {
      color:#0D8390;
      float: left;
      background: #FFFFFF;
      border: 1px solid #0D8390;
      box-sizing: border-box;
      width: 70px;
      height: 44px;
    }

    &.btn-prev:hover {
      box-shadow: 0 4px 12px 1px rgba(0, 0, 0, .2),
        0 3px 3px 0 rgba(0, 0, 0, .14),
        0 3px 5px 0 rgba(0, 0, 0, .12);
    }
  }
}

.right-scope-body {
  background-color: #ffffff;
  // border: 1px solid #000000;
  // border-radius: 15px;
  // margin-left: 16%;
  padding: 1em;
  margin: 0 auto;
  // width: 90%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;  


  .right-scope-wrapper {
    height: 50vh;
    overflow-y: auto;
    margin-bottom: 1em;
    &.review_screen{
      width: 100%;
    }
   
    .expansion-panel-custom{
      border-top: none;
      border-bottom: 2px solid #007680;
      margin: 0;
    }
  }

  .right-stpper-nav {
    text-align: center;
    padding: 1em;
  }


}

.additional-strip {
  margin: $margin-primary;
  display: flex;
  justify-content: space-between;
  width: $page-body-width;

  div {
    height: $additional-div-height;
  }

  div:first-child {
    background: rgb(0, 85, 135);
  }

  div:last-child {
    background: transparent;
  }

  &.top {
    div:first-child {
      border-top-right-radius: $border-radius-primary;
      width: $train-width;
    }

    div:last-child {
      border-top-left-radius: $border-radius-primary;
      width: $train-width-right;
    }
  }

  &.bottom {
    div:first-child {
      border-bottom-right-radius: $border-radius-primary;
      width: $train-width;
    }

    div:last-child {
      border-bottom-left-radius: $border-radius-primary;
      width: $train-width-right;
    }
  }
}

.main-form-stepper {
  margin: $margin-primary;
  width: $page-body-width;
  background: linear-gradient(90deg, rgb(0, 85, 135) 50%, transparent 50%);
}
